import React, { useState } from "react"
import { graphql } from "gatsby"

import { Layout } from "../components/common/"
import { ContentSectionBlock, HeroCarousel } from "../components/home"
import SEO from "../components/common/SEO"
import { isNotPlaceholder } from "../utils/common/content"

export default ({ data }) => {
  const homePageContent = data.allContentfulHomePage.edges.filter(
    isNotPlaceholder
  )[0].node
  const components = homePageContent.components
  const heroArtists = homePageContent.heroArtists

  const imageUrl =
    homePageContent.homePageMetaContent &&
    homePageContent.homePageMetaContent.metaImage
      ? homePageContent.homePageMetaContent.metaImage.file.url
      : undefined

  const twitterImageUrl =
    homePageContent.homePageMetaContent &&
    homePageContent.homePageMetaContent.metaImageTwitter
      ? homePageContent.homePageMetaContent.metaImageTwitter.file.url
      : undefined

  const [useDarkLayout, setUseDarkLayout] = useState(
    heroArtists[0].useDarkLayout
  )

  return (
    <Layout hasHero headerTheme={useDarkLayout ? "dark" : "light"}>
      <SEO
        title="Columbia Artists"
        description={
          homePageContent.homePageMetaContent
            ? homePageContent.homePageMetaContent.metaDescription
                .metaDescription
            : undefined
        }
        imageUrl={imageUrl && "https:" + imageUrl}
        twitterImageUrl={twitterImageUrl && "https:" + twitterImageUrl}
      />

      {heroArtists && (
        <HeroCarousel
          heroArtists={heroArtists}
          onSlideChange={artist => setUseDarkLayout(artist.useDarkLayout)}
        />
      )}

      {components &&
        components.map(
          (
            {
              captionTitle,
              captionCopy,
              textLinkUrl,
              videoLinkUrl,
              blockImage,
            },
            i
          ) => {
            return (
              <ContentSectionBlock
                key={i}
                captionTitle={captionTitle}
                captionText={captionCopy.captionCopy}
                fluid={blockImage.fluid}
                textLinkUrl={textLinkUrl}
                videoLinkUrl={videoLinkUrl}
                homePageContent={homePageContent}
                alignment={i % 2 === 0 ? "left" : "right"}
              />
            )
          }
        )}
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allContentfulHomePage {
      edges {
        node {
          title
          homePageMetaContent {
            metaDescription {
              metaDescription
            }
            metaImageTwitter {
              file {
                url
              }
            }
            metaImage {
              file {
                url
              }
            }
          }
          components {
            ...allTextandImageBlocks
          }
          heroArtists {
            ...ContentfulArtist
          }
        }
      }
    }
  }

  fragment allTextandImageBlocks on ContentfulComponentTextAndImageBlock {
    __typename
    captionTitle
    captionCopy {
      captionCopy
    }
    textLinkUrl
    videoLinkUrl
    blockImage {
      fluid(maxWidth: 800, maxHeight: 450, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      file {
        url
      }
    }
  }

  fragment ContentfulArtist on ContentfulArtist {
    __typename
    name
    slug
    useDarkLayout

    heroImage {
      fluid(maxWidth: 1600, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    heroCarouselImage {
      fluid(maxWidth: 1600, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    mobileHeroImage {
      fluid(
        maxWidth: 750
        maxHeight: 810
        quality: 70
        resizingBehavior: FILL
        cropFocus: CENTER
      ) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    croppedMobileHeroImage: heroImage {
      fluid(
        maxWidth: 750
        maxHeight: 810
        quality: 70
        resizingBehavior: FILL
        cropFocus: FACES
      ) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    croppedMobileHeroCarouselImage: heroCarouselImage {
      fluid(
        maxWidth: 750
        maxHeight: 810
        quality: 70
        resizingBehavior: FILL
        cropFocus: FACES
      ) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }

    categories {
      name
      parentCategory: category {
        name
      }
      keepPlural
    }
  }
`
