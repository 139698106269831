import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import media from "../../styles/media"
import { VideoEmbed } from "../artist/VideoEmbed"

const ContentSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.black};
  margin: 0;

  ${media.tablet`
    flex-direction: ${props =>
      props.alignment === "left" ? "row" : "row-reverse"};
  `}
`

const ContentBlock = styled.div`
  text-align: left;
  height: 0;
  padding-top: 56.25%;
  position: relative;

  h1,
  h2 {
    display: inline;
    font-size: 20px;
    line-height: 32px;
    margin: 0;

    ${media.desktop`
      font-size: 24px;
      line-height: 52px;
    `}
  }

  h1 {
    font-family: StanleyRegular, serif;
    font-style: italic;
    font-weight: normal;
  }

  h2 {
    font-family: StanleyBold, serif;
    a {
      text-decoration: none;
      color: ${props => props.theme.black};

      &:hover {
        color: ${props => props.theme.red};
      }
    }
  }

  ${media.tablet`
    align-self: center;
    padding-top: 28.125%;
    width: 100%;
  `};
`

const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0%;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 20px;

  ${media.tablet`
    padding: 0 40px;
  `}
`

const ImageSectionBlock = styled.a`
  display: block;
  width: 100%;
  margin: 0;
  padding-top: 56.25%;
  position: relative;

  ${media.tablet`
    padding-top: 28.125%;
  `}
`

// Gives the video player a container to fill with the right aspect ratio.
const AssetWrapper = styled.div`
  background-color: black;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const ContentSectionBlock = ({
  captionTitle,
  captionText,
  fluid,
  alignment,
  textLinkUrl,
  videoLinkUrl,
}) => {
  return (
    <ContentSectionContainer alignment={alignment}>
      <ImageSectionBlock href={textLinkUrl} isVideo={videoLinkUrl}>
        <AssetWrapper>
          {videoLinkUrl ? (
            <VideoEmbed url={videoLinkUrl} />
          ) : (
            <Img alt={captionTitle} fluid={fluid} />
          )}
        </AssetWrapper>
      </ImageSectionBlock>
      <ContentBlock>
        <Content>
          <div>
            <h1>{captionTitle} </h1>
            <h2>
              <a href={textLinkUrl}>{captionText}</a>
            </h2>
          </div>
        </Content>
      </ContentBlock>
    </ContentSectionContainer>
  )
}

ContentSectionBlock.propTypes = {
  captionTitle: PropTypes.string,
  captionText: PropTypes.string,
  textLinkUrl: PropTypes.string,
  videoLinkUrl: PropTypes.string,
  fluid: PropTypes.object,
  alignment: PropTypes.oneOf(["left", "right"]),
}
